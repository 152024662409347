import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useImmer } from "use-immer";

import { AuthContext } from "../AuthContext";

import QuestionShowcase from "./QuestionShowcase";
import Button from "../Button";
import SubmitModal from "./SubmitSuccessModal";
import { SignIn } from "../SignIn";
import {
  QuizAnswersData,
  QuizDataResponse,
  Option,
  Question as QuestionType,
} from "../interfaces";

import Loading from "../Loading";

interface Props {
  location: Location;
}

const QuizContainer = styled.div`
  display: grid;
  text-align: center;
  padding: 0px 80px;
  @media (max-width: 700px) {
    padding: 10px;
  }
  h1 {
    margin-top: 0;
    font-family: "Bangers";
    font-size: 3rem;
    padding: 0.5rem 2rem;
    display: grid;
    place-items: center;
    text-shadow: -2px -2px yellow, 2px 2px orange, 4px 4px orange;
    background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
  }
`;

const Question = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? `grid` : `none`)};
  grid-gap: 10px;
  padding: 25px 50px;
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.1);
  justify-self: center;
  width: 90%;
  max-width: 2200px;
  h2 {
    margin: 0;
  }
  button {
    cursor: pointer;
    justify-self: center;
    text-align: center;
    max-width: 400px;
    padding: 25px;
    text-decoration: none;
    color: #222;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 700;
    &:hover {
      opacity: 0.75;
    }
    border: 2px solid greenyellow;
  }
`;

const DoneOptions = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 10px;
`;

const QuizHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 0.8rem;
  }
`;

const QuizReader = ({ location }: Props) => {
  const { auth, getQuizByUuid, saveQuizResponse } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [quizData, setQuizData] = useState<QuizDataResponse>(null);
  const [activeQuestionNumber, setActiveQuestionNumber] = useState<number>(0);
  const [selected, setSelected] = useState<Option["uuid"]>(null);
  const [done, setDone] = useState<boolean>(false);

  const [submitOpen, setSubmitOpen] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [answersData, setAnswersData] = useImmer<QuizAnswersData>({
    userUuid: auth.uid,
    submitterName: auth.username,
    quizUuid: quizData?.uuid || "",
    results: {},
  });

  const saveChoice = (
    question: QuestionType["uuid"],
    chosenOption: Option["uuid"]
  ) => {
    setAnswersData((draft) => {
      draft.results[question] = chosenOption;
      if (
        Object.keys(draft.results).length ===
        Object.keys(quizData.questions).length
      ) {
        setDone(true);
      } else {
        setActiveQuestionNumber(activeQuestionNumber + 1);
      }
    });
    setSelected(null);
  };

  const submitQuizResponse = async () => {
    const success = await saveQuizResponse({
      ...answersData,
      userUuid: auth.uid,
      submitterName: auth.username,
      quizUuid: quizData.uuid,
    });
    if (success) {
      setSubmitSuccess(true);
    } else {
      setSubmitSuccess(false);
    }
    setSubmitOpen(true);
  };

  const restartQuiz = () => {
    setAnswersData((draft) => {
      draft = {
        userUuid: auth.uid,
        submitterName: auth.username,
        quizUuid: quizData.uuid || "",
        results: {},
      };
    });
    setDone(false);
    setActiveQuestionNumber(0);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search).get("id");
    const fetchQuiz = async () => {
      const resData = await getQuizByUuid(query);
      if (resData) {
        setQuizData(resData);
      }
      setLoading(false);
    };
    fetchQuiz();
  }, []);

  return (
    <QuizContainer>
      {loading && <Loading />}
      {!loading &&
        quizData &&
        (!done ? (
          <>
            <QuizHeader>
              <h1>{quizData.name}</h1>
              <span>
                Question {activeQuestionNumber + 1} of{" "}
                {Object.entries(quizData.questions).length}
              </span>
            </QuizHeader>
            {Object.entries(quizData.questions).map((record) => {
              const { title, options, uuid } = record[1];
              const option1 = options[Object.keys(options)[0]];
              const option2 = options[Object.keys(options)[1]];

              return (
                <Question
                  key={uuid}
                  active={
                    Object.entries(quizData.questions)[
                      activeQuestionNumber
                    ][0] === uuid
                  }
                >
                  <h2>{title}</h2>
                  <QuestionShowcase
                    selected={selected}
                    setSelected={setSelected}
                    option1={option1}
                    option2={option2}
                  />
                  <Button
                    disabled={!selected}
                    type={selected ? "primary" : "secondary"}
                    onClick={() => saveChoice(uuid, selected)}
                  >
                    Make Your Choice
                  </Button>
                </Question>
              );
            })}
          </>
        ) : (
          <div>
            <h1>{quizData.name}</h1>
            <h2>Your responses are locked in!</h2>
            {!auth.isUserSignedIn ? (
              <SignIn heading={`Sign in to complete`} />
            ) : (
              <DoneOptions>
                <Button type={"primary"} onClick={() => submitQuizResponse()}>
                  Submit Your Response
                </Button>
                <Button type={"tertiary"} onClick={() => restartQuiz()}>
                  Reset Responses
                </Button>
              </DoneOptions>
            )}
          </div>
        ))}
      {!loading && !quizData && (
        <>
          <p>Hmm we couldn't find your poll...</p>
          <Link to="/my-polls">
            <Button onClick={() => false} type="secondary">
              Back to All Polls
            </Button>
          </Link>
        </>
      )}
      <SubmitModal
        submitSuccess={submitSuccess}
        open={submitOpen}
        onClose={() => setSubmitOpen(false)}
      />
    </QuizContainer>
  );
};

export default QuizReader;
