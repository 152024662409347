import React, { useContext } from "react";
import { StyledFirebaseAuth } from "react-firebaseui";
import firebase from "firebase";
import { fireBaseApp } from "../base";

import { AuthContext } from "./AuthContext";
import styled from "styled-components";
import Loading from "./Loading";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  callbacks: {
    signInSuccessWithAuthResult: (authResult: any, redirectUrl?: string) => {
      // Force not to redirect
      return false;
    },
  },
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: "/quizBuilder",
  // We will display Github as the auth provider.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
  ],
};
const SigninContainer = styled.section`
  display: inline-grid;
  justify-content: start;
  text-align: center;
  box-shadow: 0px 2px 15px 3px rgba(0, 0, 0, 0.1);
  border-top: 5px solid greenyellow;
  margin: 25px 0px;
  padding: 25px 30px;
  max-width: 250px;
`;

interface Props {
  heading?: string;
}
export const SignIn = ({ heading }: Props) => {
  const { auth, signingIn } = useContext(AuthContext);
  return (
    <SigninContainer>
      <h3>{heading || "Get started by signing in"}</h3>
      {signingIn && <Loading />}
      <StyledFirebaseAuth
        className="loginButtons"
        uiConfig={uiConfig}
        firebaseAuth={fireBaseApp.auth()}
      />
    </SigninContainer>
  );
};
