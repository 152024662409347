import React from "react";
import loadingIcon from "../images/loading.svg";
import styled from "styled-components";

const Loader = styled.div`
  display: grid;
  grid-gap: 10px;
  p {
    margin: 0;
  }
`;

const Loading = () => {
  return (
    <Loader>
      <p>
        <img src={loadingIcon} alt="loading" />
      </p>
      <p>Loading...</p>
    </Loader>
  );
};

export default Loading;
