import React from "react";
import { Layout } from "../layouts/Layout";
import QuizReader from "../components/quiz-reader/QuizReader";
import styled from "styled-components";
import SEO from "../components/Seo";

const TakeQuizContainer = styled.section`
  padding: 25px;
`;

export const QuizReaderPage = ({ location }: any) => {
  return (
    <Layout>
      <SEO title={"This vs That: Take a Poll"} />
      <TakeQuizContainer>
        <QuizReader location={location} />
      </TakeQuizContainer>
    </Layout>
  );
};

export default QuizReaderPage;
