import React from "react";
import { Option as OptionType } from "../interfaces";
import styled from "styled-components";

const Showcase = styled.div`
  display: grid;
  grid-gap: 25px;
  padding: 0px 25px;
  grid-template-columns: 1fr 50px 1fr;
  @media (max-width: 700px) {
    grid-template-columns: auto;
    grid-template-rows: 1fr 50px 1fr;
  }
  box-sizing: border-box;
  span {
    font-weight: 700;
    font-size: 2rem;
    align-self: center;
    background: greenyellow;
    border-radius: 25px;
    height: 50px;
  }
`;

const Option = styled.div<{ active: boolean }>`
  display: grid;
  grid-template-rows: auto 300px;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  border: 2px solid ${({ active }) => (active ? `greenyellow` : `transparent`)};
  &:hover {
    border: 2px solid greenyellow;
  }
  img {
    max-height: 100%;
    max-width: 100%;
    place-self: center;
  }
`;

const This = styled.div`
  display: grid;
  transform: skew(2deg);
  background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
  background-image: radial-gradient(
      ellipse farthest-corner,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 35%,
      #ffffff 30%,
      #ffffff 40%,
      rgba(0, 0, 0, 0) 90%
    ),
    radial-gradient(
      ellipse farthest-corner at 0px 0px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    radial-gradient(
      ellipse farthest-corner at 8px 8px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    radial-gradient(
      ellipse farthest-corner at 0px 8px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 40%
    ),
    radial-gradient(
      ellipse farthest-corner at 8px 0px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(
      40deg,
      #bd2d10 0,
      #f4502f 30%,
      #ff6e51 50%,
      #f4502f 70%,
      #bd2d10 100%
    );
  padding: 25px;
`;

const That = styled.div`
  padding: 25px;
  display: grid;
  transform: skew(2deg);
  background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
  background-image: radial-gradient(
      ellipse farthest-corner,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 35%,
      #ffffff 30%,
      #ffffff 40%,
      rgba(0, 0, 0, 0) 90%
    ),
    radial-gradient(
      ellipse farthest-corner at 0px 0px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    radial-gradient(
      ellipse farthest-corner at 8px 8px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    radial-gradient(
      ellipse farthest-corner at 0px 8px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 40%
    ),
    radial-gradient(
      ellipse farthest-corner at 8px 0px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(
      40deg,
      #038ecf 0,
      #51cbf1 30%,
      #7ff9ff 50%,
      #51cbf1 70%,
      #038ecf 100%
    );
`;

interface Props {
  selected: OptionType["uuid"];
  option1: OptionType;
  option2: OptionType;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

const QuestionShowcase = ({
  option1,
  option2,
  selected,
  setSelected,
}: Props) => {
  return (
    <Showcase>
      <Option
        active={selected === option1.uuid}
        onClick={() => setSelected(option1.uuid)}
      >
        <p>{option1.data.text}</p>
        {option1.data.imageUrl && (
          <This>
            <img src={option1.data.imageUrl} alt="this" />
          </This>
        )}
      </Option>
      <span>vs.</span>
      <Option
        active={selected === option2.uuid}
        onClick={() => setSelected(option2.uuid)}
      >
        <p>{option2.data.text}</p>
        {option2.data.imageUrl && (
          <That>
            <img src={option2.data.imageUrl} alt="that" />
          </That>
        )}
      </Option>
    </Showcase>
  );
};

export default QuestionShowcase;
