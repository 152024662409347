import React from "react";
import Popup from "reactjs-popup";
import { Link } from "gatsby";
import styled from "styled-components";
import Button from "../Button";
import rocket from "../../images/rocket.svg";

interface Props {
  submitSuccess: boolean;
  open: boolean;
  onClose: () => void;
}

const PopupContent = styled.div`
  padding: 50px;
  display: grid;
  justify-content: center;
  text-align: center;
  img {
    justify-self: center;
    margin: 20px;
    height: 64px;
    width: 64px;
  }
`;

const SubmitModal = ({ submitSuccess, open, onClose }: Props) => {
  return (
    <Popup open={open} onClose={onClose}>
      <PopupContent>
        {submitSuccess ? (
          <>
            <h2>Success, you're all done!</h2>
            <img src={rocket} alt="rocket" />
            <p>Your valuable opinions have been recorded.</p>
            <Link to="/my-polls">
              <Button onClick={() => false} type={"primary"}>
                Back to All Quizzes
              </Button>
            </Link>
          </>
        ) : (
          <>
            <h2>Oops, something went wrong</h2>
            <Link to="/my-polls">Try again</Link>
          </>
        )}
      </PopupContent>
    </Popup>
  );
};

export default SubmitModal;
